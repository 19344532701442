@import "~antd/dist/antd.css";

body {
  font-size: 1.2em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #000000;
  background-color: black;
  background-image: url("../src/Assets/blurBg.png"),
    url("../src/Assets/logoBg.png"), url("../src/Assets/backgroundImage.png");
  overflow: hidden;
  background-attachment: fixed;
  position: relative;
  margin:0 auto;
  height: 100vh;
  justify-content: center;
  display: flex;
  align-items: center;
}

.container {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.news-container {
  width: 100%;
  height: 0%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.9);
  overflow-y: hidden;
  transition: 0.5s;
  background-size: 100% 100%;
}

.news-btn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}

.news-style {
  width: 70%;
  height: 60%;
}

.news-title {
  font-size: 1.6em;
}

.news-desc {
  font-size: 1.3em;
  padding-top: 30px;
}


.content-container{
  background-color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  z-index: 999;
  width: 400px;
  text-align: center;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.qr-container {
  top: -55px;
  position: absolute;
  left: 0;
  right: 0;
  height: auto;
  width: 100%;
}

.ref-container {
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: 80px;
}

.ref-label-txt {
  margin-top: 20px;
  font-size: 1.3em;
  font-weight: bold;
  align-self: center;
}

.ref-style {
  width: 100%;
  border-radius: 10px;
  border: 1px solid #000000;
  height: 50px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;
}

.ref-txt {
  align-items: center;
  width: 250px;
  display: flex;
  height: 40px;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 1.3em;
  padding-right: 40px;
}

.ref-btn{
  background-color: transparent;
  border: none;
  outline: none;
  align-items: center;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.ref-empty {
  text-align: center;
  width: 100%;
}

.install-btn {
  height: 40px;
  width: 100%;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  font-weight: bold;
  border: none;
  color: #ffffff;
  overflow: auto;
  margin-top: 20px;
}

.step-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  text-align: left;
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
}

.announcement-label-txt {
  text-align: left;
  margin: 10px;
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 20px;
}

.announcement-container {
  justify-content: flex-start;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  align-self: baseline;
  flex-direction: row;
  display: flex;
  border-radius: 20px;
  padding: 5px;
  margin: auto;
  min-width: auto;
  min-height: auto;
  height: 200px;
}

.icon-container {
  height: 100px;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.annoucement-icon {
  height: 80px;
}

.announcement-style{
  height: 100%;
  padding:10px;
  overflow: auto;
}

.announcement-title {
  text-align: left;
  font-weight: bold;
}

.announcement-content{
  text-align: left;
  font-weight: 400;
  font-size: 16px;
}

.lang-container {
  width: 100%;
  color: #ffffff;
  margin-top: 58px;
  bottom: 10px;
  position: relative;
}

.lang-style {
  flex-direction: row;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.lang-txt {
  font-size: 1.5em;
  border: none;
  background: none;
  font-weight: 700;
  text-shadow: 2px 2px 5px #000000;
}

.copy-right {
  font-size: 16px;
  text-shadow: 2px 2px 5px #000000;
  bottom: 10px;
  text-align: center;
  width: 100%;
}

.ios-btn{
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
  background-image: linear-gradient(45deg, #350bc8, #8633c3, #b72dbc);
}

.overlay {
  display: none;
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.cancel-btn {
  background: linear-gradient(45deg, #d7437c, #f6b56b);
}

.cancel-txt-btn{
  color: #ffffff;
}


.download-step-img {
  height: 100%;
  width: 100%;
}

.ant-modal-body{
  background-color: #000000;
}

.ant-modal-header{
  display: none;
  background-color: #000000;
}

.ant-modal-title{
  color: #ffffff;
}

.ant-modal-wrap{
  background: #000000;
}

.modal-content{
  top:0;
}

.ios-step-container {
  text-align: left;
  margin-top: 20px;
}

.ios-step-txt {
  color: #ffffff;
}

.ios-step2-txt{
  color: #ffffff;
  margin-top: 5px
}

.ios-tips{
  color:red;
  margin-top: 5px;
  font-size: 18px;
}

.ios-txt-btn{
  color: #ffffff;
}

@media only screen and (min-device-width: 280px) and (max-device-width: 768px) {
  body {
    font-size: 1em;
    overflow: auto;
  }

  .content-container{
    margin-top: 200px;
    width: 327px;
  }
  
  .annoucement-icon {
    height: 60px;
  }
}

